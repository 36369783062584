@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//colors neutral
$black: #000000;
$white: #ffffff;
$cornflower-blue:#005685;
$wild-blue-yonder:#838AB3;
$corn-blue: #2D2E4D;
$martinique:#34315D;
//used on background gradients
$port-gore:#281F4B;
$dark-gunmetal:#1C1836;
$mirage:#121324;
//--special border for flags in titles
$flag-rounded: 30px;
//fonts
$body-font: 'Poppins',
sans-serif;
//breakpoints
//Low Resolution Mobiles (Landscape)
$xs320: 320px;
$xsmall: 768px;
$xsmall-min: $xsmall;
$small: 992px;
$small-min: $small;
$medium: 1024px;
$medium-min: $medium;
$large: 1200px;
$large-min: $large;
$xsmall-max: calc($small-min - 1);
$small-max: calc($medium-min - 1);
$medium-max: calc($large-min - 1);
$gap: 10px;
//sidebar-width
$xs-sidebar: 100%;
$lg-sidebar: 108px;
//--container stops growing wide at large screen
//--need to use same $lg-sidebar value
$lg-main-wraper: calc(100% - 108px);
$lg-main-container: $large-min;