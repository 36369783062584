/* Variable, Breakpoints */

@import './default-elements/breakpoints.scss';
@import './default-elements/variables.scss';
html,
body {
    font-family: $body-font;
    background: transparent linear-gradient(180deg, $port-gore 0%, $dark-gunmetal 55%, $mirage 100%) 0% 0% no-repeat padding-box;
    height: auto;
}
.vjs-control-bar{
    background-color: transparent  !important;
}
.container {
    margin: 0 auto;
    position: relative;
    // z-index: 1;
    width: 100%;
    @media (min-width: $xsmall-min) {
        max-width: $medium;
    }
}
#smartbanner.android, .sb-button{
    border: none !important;
}
@media (max-width: $xsmall) {
    button{
        &.vjs-big-play-button{
            position: absolute !important;
            top: 133px !important;
        }
    }
    .np__player_widget{
        position: relative !important;
        margin-top: 144px !important;
    }
    .np__controls_elements {
        .np__avatar_element{
         display: none !important;
        }
    }
    .np__btn_controls{
        .fa-share-alt{
            display: none !important;
        }
    }
}
@media (min-width: $xsmall) {
    button{
        &.vjs-big-play-button{
            position: absolute !important;
            top: 154px !important;
        }
    }
    .np__player_widget{
        position: relative !important;
        margin-top: 225px !important;
    }

}
@media (min-width: $medium) {
    button{
        &.vjs-big-play-button{
            position: absolute !important;
            top: 300px !important;
        }
    }
    .np__player_widget{
        position: relative !important;
        margin-top: 296px !important;
    }
}

